export const request =
  (config, fbAuth) =>
  (url, opts = {}) => {
    if (!opts.method) opts.method = "GET"
    const { token } = fbAuth

    return fetch(`${config.public.MYTHOS_RUNNER}/${url}`, {
      method: opts.method,
      headers: { idToken: token, ...opts.headers },
    }).then((resp) => resp.json())
  }

export const runnerRequest =
  (config, fbAuth) =>
  (url, opts = {}) => {
    if (!opts.method) opts.method = "GET"
    // const { token } = fbAuth

    return fetch(`${config.public.MYTHOS_RUNNER}/${url}`, {
      method: opts.method,
      headers: { ...opts.headers },
      body: JSON.stringify(opts.body || {})
    }).then((resp) => resp.json())
  }

export const localAPIRequest =
  (config, firebase_auth) =>
  (url, opts = {}) => {
    if (!opts.method) opts.method = "GET"
    const { token } = firebase_auth

    return fetch(`${config.public.MYTHOS}/api/${url}`, {
      method: opts.method,
      headers: { idToken: token, ...opts.headers },
      ...opts
    }).then((resp) => resp.json())
  }